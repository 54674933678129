import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:flex md:items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_AOS = _resolveComponent("AOS")!
  const _directive_aos_anchor = _resolveDirective("aos-anchor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_Paragraph, { class: "md:mr-6 text-gray-600 text-base" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    })), [
      [_directive_aos_anchor, void 0, "header-action"]
    ]),
    _createVNode(_component_AOS, {
      type: "fade",
      delay: "1000",
      easing: "ease-in",
      anchor: "header-action",
      "anchor-placement": "top-bottom",
      "is-group": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Anchor, {
          class: "mt-8 md:mt-0 text-base",
          route: _ctx.route
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.routeText), 1)
          ]),
          _: 1
        }, 8, ["route"])
      ]),
      _: 1
    })
  ]))
}
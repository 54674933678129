import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_HeaderAction = _resolveComponent("HeaderAction")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "mt-32 lg:mt-64",
          header: _ctx.lang.not_found_h1
        }, null, 8, ["header"]),
        _createVNode(_component_AOS, {
          type: "fade",
          easing: "ease",
          duration: "800",
          delay: "1000"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { class: "mt-8 mb-32 md:mt-11" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_HeaderAction, {
                      title: _ctx.lang.header_action,
                      "route-text": _ctx.lang.header_button,
                      route: _ctx.Route.Home
                    }, null, 8, ["title", "route-text", "route"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_MetaInfo, {
      title: _ctx.lang.seo_title,
      description: _ctx.lang.seo_desc
    }, null, 8, ["title", "description"])
  ], 64))
}